import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../../component/header-footer/Header";
import Footer from "../../component/header-footer/Footer";

const Main = () => {
  return (
    <div>
      <Header />
      <section className="container mx-auto px-4 mt-20 md:mt-36">
        <Outlet />
      </section>
      <div className="mt-20">
        <Footer />
      </div>
    </div>
  );
};

export default Main;
