import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import Title from "../../component/Helmet/Title";
import {
  useGetCategoriesQuery,
  useGetSubcategoryProductsQuery,
} from "../../redux/Features/ServicesApi";
import ProductsComponent from "../../component/Products/ProductsComponent";
import ProductCardSkeleton from "../../component/Skeleton/ProductCardSkeleton";

const SubcategoryProducts = () => {
  const { slug } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // get categories of products from backend.
  const { data: categories } = useGetCategoriesQuery();

  // get subcategories from backend.
  const { data: subcategoriesProducts, isLoading } =
    useGetSubcategoryProductsQuery(slug);

  return (
    <div className="my-10 lg:my-44">
      {/* React helmet. */}
      <Title title={slug} content={"This is SubcategoryProducts page"} />
      <div className="bg-white lg:flex justify-between items-center border-b py-4 pe-5">
        {/* back button. */}
        <div className="hidden lg:block">
          <Button variant="contained" color="success">
            <Link to={"/"}>Back</Link>
          </Button>
        </div>
        {/* product sorting */}
        <div className="flex justify-center lg:justify-between items-center gap-4 mx-auto">
          <div>Sort by:</div>
          <form className="max-w-sm">
            <select
              id="filter"
              className="rounded-full text-dark focus:ring-primary focus:border-primary block w-full p-2"
            >
              <option value={"Best Match"} className="border-0">
                Best Match
              </option>
              <option value="TS">Top sells</option>
              <option value="NA">Newest arrivals</option>
              <option value="LH">Price low to high</option>
              <option value="HL">Price high to low</option>
            </select>
          </form>
        </div>
      </div>
      <div className="mx-auto flex-wrap flex justify-center p-2 items-center py-2 gap-4 bg-white border-b text-lg">
        <div className="mx-auto flex-wrap flex justify-center p-2 items-center py-2 gap-4 bg-white border-b text-lg">
          {/* all categoryProducts nav */}
          <div>
            <Link
              to={"/products-collection"}
              className={"bg-secondary-200 border rounded-md px-3 py-2"}
            >
              All
            </Link>
          </div>
          {categories?.data?.map((c, index) => (
            <div key={c?.id}>
              <Link
                to={`/products-collection/category-products/${c?.slug}`}
                id={c}
                className={"bg-secondary-200 border rounded-md px-3 py-2"}
              >
                {c?.name}
              </Link>
            </div>
          ))}
        </div>
      </div>

      {/* Products */}
      {isLoading ? (
        <ProductCardSkeleton />
      ) : (
        <ProductsComponent products={subcategoriesProducts?.data} />
      )}
    </div>
  );
};

export default SubcategoryProducts;
