import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation, Autoplay } from 'swiper/modules';
import { Card, CardActionArea, CardContent, Skeleton } from '@mui/material';
import { Link } from 'react-router-dom';

const CategoriesSkeleton = () => {
  return (
    <div className="my-10">
      <Skeleton width={200} />
      <div className="mt-5">
        <Swiper
          slidesPerView={2}
          loop={true}
          spaceBetween={6}
          breakpoints={{
            640: {
              slidesPerView: 3,
              spaceBetween: 12,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 16,
            },
            1024: {
              slidesPerView: 6,
              spaceBetween: 20,
            },
          }}
          modules={[Navigation, Autoplay]}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          className=" mt-4 space-x-2 mySwiper cursor-pointer"
        >
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
            <SwiperSlide key={i}>
              <Card sx={{ maxWidth: 1000 }} className="my-2 animate-pulse">
                <Link to={'/products-collection/electronics'}>
                  <CardActionArea>
                    <div className="bg-secondary-200 w-full h-44"></div>
                    <CardContent>
                      <Skeleton />
                    </CardContent>
                  </CardActionArea>
                </Link>
              </Card>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default CategoriesSkeleton;
