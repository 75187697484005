import { Button } from "@mui/material";
import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import ProductsComponent from "../../component/Products/ProductsComponent";
import { useGetSearchProductQuery } from "../../redux/Features/ServicesApi";
import ProductCardSkeleton from "../../component/Skeleton/ProductCardSkeleton";
import Title from "../../component/Helmet/Title";

const SearchProducts = () => {
  const { name } = useParams();

  //* Get products by search name.
  const { data, isLoading } = useGetSearchProductQuery(name);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="my-10 lg:my-44">
      <Title
        title={name}
        content={`This is search products by ${name} page.`}
      />
      {/* back button. */}
      <div className="hidden lg:block">
        <Button variant="contained" color="success">
          <Link to={"/"}>Back</Link>
        </Button>
      </div>

      {/* Products */}
      {isLoading ? (
        <ProductCardSkeleton />
      ) : (
        <ProductsComponent products={data?.data} />
      )}
    </div>
  );
};

export default SearchProducts;
