import React from "react";
import { Helmet } from "react-helmet";

const Title = ({ title, content }) => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title} - Banngomart</title>
        <meta name="description" content={content} />
        <link rel="canonical" href="https://cmp.banggomart.com/" />
      </Helmet>
    </div>
  );
};

export default Title;
