import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Autoplay, Pagination } from "swiper/modules";
import "./swiper.css";
import CategoriesDropdown from "../CategoriesDropdown";
import BannerSkeleton from "../Skeleton/BannerSkeleton";
import { useGetBannersQuery } from "../../redux/Features/ServicesApi";
import { Link } from "react-router-dom";

//* This is Banner section
const Banner = () => {
  // Get banner from backend.
  const { data, isLoading } = useGetBannersQuery();

  return (
    <>
      {isLoading ? (
        <BannerSkeleton />
      ) : (
        <section className="lg:flex items-start justify-between gap-2 py-2 mx-auto">
          <div className="hidden lg:block lg:w-56 mx-auto">
            <CategoriesDropdown />
          </div>
          <div className="w-full lg:w-5/6 mx-auto">
            {/* Swiper carousel */}
            <Swiper
              slidesPerView={1}
              loop={true}
              fadeEffect={true}
              modules={[Navigation, Autoplay, Pagination]}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{ clickable: true }}
              navigation={true}
              className="mySwiper"
            >
              {data?.data?.map((s, index) => (
                <SwiperSlide key={s?.id}>
                  <Link
                    to={`${
                      index === 0
                        ? "/products-collection/category-products/smart-gadgets"
                        : index === 1
                        ? ' "/products-collection/category-products/smart-gadgets"'
                        : index === 2
                        ? "/products-collection/category-products/smart-gadgets"
                        : "/products-collection/category-products/health-beauty"
                    }`}
                  >
                    <img
                      src={s?.imageUrl}
                      alt="Slider"
                      className="rounded-md"
                    />
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </section>
      )}
    </>
  );
};

export default Banner;
