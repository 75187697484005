import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import Title from '../../component/Helmet/Title';
import {
  useGetCategoriesQuery,
  useGetCategoryProductsQuery,
} from '../../redux/Features/ServicesApi';
import ProductsComponent from '../../component/Products/ProductsComponent';
import ProductCardSkeleton from '../../component/Skeleton/ProductCardSkeleton';

// categories products page.
const CategoryProducts = () => {
  const { slug } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Get the categories of products.
  const { data: categories, isLoading: categoryLoading } =
    useGetCategoriesQuery();

  // Get the subcategories from categories filtering by slug
  const subCategories = categories?.data?.find((c) => c.slug === slug);

  // Get the categories products by slug
  const { data: categoriesProducts, isLoading } =
    useGetCategoryProductsQuery(slug);

  return (
    <div className="my-10 lg:my-44">
      <Title title={slug} content={'This is Category products page'} />
      <div className="bg-white lg:flex justify-between items-center border-b py-4 pe-5">
        <div className="hidden lg:block">
          <Button variant="contained" color="success">
            <Link to={'/'}>Back</Link>
          </Button>
        </div>
        <div className="flex justify-center lg:justify-between items-center gap-4 mx-auto">
          <div>Sort by:</div>
          <form className="max-w-sm">
            <select
              id="filter"
              className="rounded-full text-dark focus:ring-primary focus:border-primary block w-full p-2"
            >
              <option value={'Best Match'} className="border-0">
                Best Match
              </option>
              <option value="TS">Top sells</option>
              <option value="NA">Newest arrivals</option>
              <option value="LH">Price low to high</option>
              <option value="HL">Price high to low</option>
            </select>
          </form>
        </div>
      </div>
      <div className="mx-auto flex-wrap flex justify-center p-2 items-center py-2 gap-4 bg-white border-b text-lg">
        <div className="mx-auto flex-wrap flex justify-center p-2 items-center py-2 gap-4 bg-white border-b text-lg">
          <div>
            <Link
              to={'/products-collection'}
              className={'bg-secondary-200 border rounded-md px-3 py-2'}
            >
              All
            </Link>
          </div>

          {/* subcategories */}
          <div>
            <Link
              to={`/products-collection/category-products/${subCategories?.slug}`}
              className={'bg-secondary-200 border rounded-md px-3 py-2'}
            >
              {subCategories?.name}
            </Link>
          </div>
          {subCategories?.subcategories?.length > 0 &&
            subCategories?.subcategories?.map((c, index) => (
              <div key={c?.id}>
                <Link
                  to={`/products-collection/subcategory-products/${c?.slug}`}
                  id={c}
                  className={'bg-secondary-200 border rounded-md px-3 py-2'}
                >
                  {c?.name}
                </Link>
              </div>
            ))}
        </div>
      </div>

      {isLoading || categoryLoading ? (
        // if loading show products loader skeleton.
        <ProductCardSkeleton />
      ) : (
        // products component.
        <ProductsComponent products={categoriesProducts?.data} />
      )}
    </div>
  );
};

export default CategoryProducts;
