import React from 'react';
import { Button } from '@mui/material';
import { motion } from 'framer-motion';
import DeleteIcon from '@mui/icons-material/Delete';

const CartSidebarSkeleton = () => {
  return (
    <div>
      {/* Menu sidebar component contents */}
      <div className="h-full px-3 py-4 overflow-y-auto bg-secondary-50 shadow-2xl">
        <h3 className="text-xl font-medium my-4">Cart</h3>

        {/* Cart Items Skeleton */}
        <motion.div className="my-5 overflow-y-scroll h-4/5">
          {[...Array(3)].map((_, index) => (
            <div
              className="flex items-center gap-2 border shadow-md p-2 animate-pulse"
              key={index}
            >
              <div className="w-2/4 bg-gray-200 h-20"></div>
              <div className="w-2/4">
                <p className="bg-gray-200 h-4 w-full mb-2"></p>
                <p className="bg-gray-200 h-4 w-1/2"></p>
              </div>
              <div className="w-1/4">
                <Button variant="text" color="error" disabled>
                  <DeleteIcon />
                </Button>
              </div>
            </div>
          ))}
        </motion.div>

        {/* Buttons Skeleton */}
        <div className="flex fixed bottom-0.5 z-30 items-center justify-between gap-2 w-64 lg:w-80">
          <Button
            variant="contained"
            color="success"
            className="w-full animate-pulse"
            disabled
          >
            <span className="text-sm lg:text-base bg-gray-200 w-full h-6"></span>
          </Button>
          <Button
            variant="contained"
            color="success"
            className="w-full animate-pulse"
            disabled
          >
            <span className="bg-gray-200 w-full h-6"></span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CartSidebarSkeleton;
