import { ShoppingCart } from '@mui/icons-material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import MenuIcon from '@mui/icons-material/Menu';
import MenuSidebar from './MenuSidebar';
import { Badge } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import CartSideBar from './CartSideBar';
import CategoriesDropdown from '../CategoriesDropdown';
import HomeIcon from '@mui/icons-material/Home';
import WidgetsIcon from '@mui/icons-material/Widgets';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import {
  useGetCountCartQuery,
  useGetGeneralDataQuery,
} from '../../redux/Features/ServicesApi';
import HeaderSkeleton from '../Skeleton/HeaderSkeleton';
import { useForm } from 'react-hook-form';
import { DataContext } from '../../provider/ContextProvider';

const Header = () => {
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);
  const [isOpenSearchBar, setIsOpenSearchBar] = useState(false);
  const [isOpenSidebar, setIsOpenSidebar] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const { ip } = useContext(DataContext);
  const sidebarRef = useRef();
  const navigate = useNavigate();
  const { register, handleSubmit, reset } = useForm();

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsCategoryOpen(false);
    }
  };

  useEffect(() => {
    if (isCategoryOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isCategoryOpen]);

  const { data: cart, isLoading } = useGetCountCartQuery(ip);
  const { data } = useGetGeneralDataQuery();

  const onSubmit = (data) => {
    navigate(`/products-collection/search-products/${data?.default_search}`);
    reset();
  };

  const onSubmitMobile = (e) => {
    e.preventDefault();
    const search = e.target.search.value;
    console.log(search);
    navigate(`/products-collection/search-products/${search}`);
    setIsOpenSearchBar(false);
    e.target.search.value = '';
  };

  return (
    <section className="bg-white">
      {isLoading ? (
        <HeaderSkeleton />
      ) : (
        <div>
          {/* Navbar or Header for large device */}
          <div className="hidden md:block">
            <div className="fixed top-0 right-0 left-0 z-30">
              <div className="container mx-auto bg-white px-4">
                <div className="flex items-center justify-between p-4">
                  <Link to={'/'}>
                    <img
                      src={data?.generalData?.logo_url}
                      alt="banngomart"
                      className="w-32"
                    />
                  </Link>
                  <div>
                    <form
                      className="max-w-full mx-auto"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <label
                        htmlFor="default_search"
                        className="mb-2 text-sm font-medium text-secondary-900 sr-only "
                      >
                        Search
                      </label>
                      <div className="relative">
                        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                          <svg
                            className="w-4 h-4 text-secondary-500 dark:text-secondary-400"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 20 20"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                            />
                          </svg>
                        </div>
                        <input
                          type="search"
                          id="default_search"
                          className="block md:w-[450px] lg:w-[500px] xl:w-[650px] 2xl:w-[800px] p-4 ps-10 text-sm text-secondary-900 border-2 border-primary-300 rounded-lg bg-secondary-50 focus:ring-primary-500 focus:border-primary-500 "
                          placeholder="Search here..."
                          {...register('default_search')}
                          required
                        />
                      </div>
                    </form>
                  </div>
                  <div
                    onClick={() => setIsCartOpen(!isCartOpen)}
                    className="cursor-pointer"
                  >
                    <Badge badgeContent={cart?.data} color="success">
                      <ShoppingCart />
                    </Badge>
                  </div>
                </div>
              </div>
              <div className="bg-primary-500">
                <div className="flex items-center justify-start mx-auto gap-5 container px-4">
                  <div
                    className="relative w-56"
                    onMouseLeave={() => setIsCategoryOpen(false)}
                  >
                    <button
                      onMouseEnter={() => setIsCategoryOpen(true)}
                      className="text-white w-full flex bg-primary-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg px-5 py-2.5 text-center items-center justify-between"
                      type="button"
                    >
                      <span> Categories</span>
                      <svg
                        className="w-2.5 h-2.5"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m1 1 4 4 4-4"
                        />
                      </svg>
                    </button>

                    <div
                      onMouseLeave={() => setIsCategoryOpen(false)}
                      className="absolute w-full bg-white"
                    >
                      {/* <!-- Dropdown menu --> */}
                      <div ref={sidebarRef}>
                        {isCategoryOpen && <CategoriesDropdown />}
                      </div>
                    </div>
                  </div>
                  {/* Navbar */}
                  <div className="w-4/5">
                    <ul className="py-2 text-white flex gap-4 items-center">
                      <li>
                        <Link
                          to="/"
                          className="block px-2 py-2 hover:bg-secondary-950 rounded-md"
                        >
                          Home
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/products-collection"
                          className="block px-2 py-2 hover:bg-secondary-950 rounded-md"
                        >
                          Shop
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={'/offer-products'}
                          className="block px-2 py-2 hover:bg-secondary-950 rounded-md"
                        >
                          Offer Products
                        </Link>
                      </li>
                      <li>
                        <a
                          href="https://cmp.banggomart.com/return/process"
                          target="_blank"
                          rel="noreferrer"
                          className="block px-2 py-2 hover:bg-secondary-950 rounded-md"
                        >
                          Return Process
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Navbar or Header for small device */}
          <div className="md:hidden block">
            <div className="container mx-auto p-4 flex bg-white items-center justify-between fixed top-0 right-0 left-0 z-30">
              <Link to={'/'}>
                <img src="/images/banggomart.png" alt="" className="w-28" />
              </Link>
              <div className="space-x-2 flex items-center gap-4">
                <div onClick={() => setIsOpenSearchBar(!isOpenSearchBar)}>
                  <SearchIcon />
                </div>
                <div
                  onClick={() => setIsCartOpen(!isCartOpen)}
                  className="cursor-pointer"
                >
                  <Badge badgeContent={cart?.data} color="success">
                    <ShoppingCart />
                  </Badge>
                </div>
                {isOpenSidebar === true ? (
                  <div onClick={() => setIsOpenSidebar(false)} className="">
                    <ClearIcon className="text-black" />
                  </div>
                ) : (
                  <div onClick={() => setIsOpenSidebar(true)} className="">
                    <MenuIcon className="text-black" />
                  </div>
                )}
              </div>
            </div>
            {/* fixed bottom navbar */}
            <div className="fixed bottom-0 left-0 right-0 mx-auto bg-secondary-50 z-30 p-2">
              <div className="flex items-center justify-between">
                <Link to={'/'} className="mx-auto text-center">
                  <HomeIcon />
                  <p>Home</p>
                </Link>
                <Link to={'/categories'} className="mx-auto text-center">
                  <WidgetsIcon />
                  <p>Categories</p>
                </Link>
                <Link
                  className="mx-auto text-center"
                  to={'/products-collection'}
                >
                  <LocalMallIcon />
                  <p>Products</p>
                </Link>
                <Link
                  className="mx-auto text-center"
                  to={'/view-cart'}
                  title="Cart"
                >
                  <Badge badgeContent={cart?.data} color="success">
                    <ShoppingCartIcon />
                  </Badge>
                  <p>Cart</p>
                </Link>
              </div>
            </div>
          </div>

          {isOpenSearchBar && (
            <div className="fixed top-16 right-0 left-0 px-4 z-30">
              <form className="max-w-md mx-auto" onSubmit={onSubmitMobile}>
                <label
                  htmlFor="default-search"
                  className="mb-2 text-sm font-medium text-secondary-900 sr-only "
                >
                  Search
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                    <svg
                      className="w-4 h-4 text-secondary-500 dark:text-secondary-400"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 20"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                      />
                    </svg>
                  </div>
                  <input
                    type="search"
                    className="block w-full p-4 ps-10 text-sm text-secondary-900 border border-primary-300 rounded-lg bg-secondary-50 focus:ring-primary-500 focus:border-primary-500"
                    placeholder="Search here..."
                    {...register('search')}
                    required
                  />
                  <button
                    type="submit"
                    className="text-white absolute end-2.5 bottom-2.5 bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 "
                  >
                    Search
                  </button>
                </div>
              </form>
            </div>
          )}
          <MenuSidebar isOpen={isOpenSidebar} setIsOpen={setIsOpenSidebar} />
          <CartSideBar isOpen={isCartOpen} setIsOpen={setIsCartOpen} />
        </div>
      )}
    </section>
  );
};

export default Header;
