import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation, Autoplay } from 'swiper/modules';
import { Card, CardActionArea, CardContent, CardMedia } from '@mui/material';
import { Link } from 'react-router-dom';
import CategoriesSkeleton from '../Skeleton/CategoriesSkeleton';

const Categories = ({ isLoading, categories }) => {
  return (
    <div className="my-5 lg:my-10">
      {isLoading ? (
        <CategoriesSkeleton />
      ) : (
        categories?.length > 0 && (
          <div className="">
            <h2 className="text-xl lg:text-3xl font-medium lg:font-bold my-5 lg:my-10 uppercase">
              Top Categories
            </h2>
            <div className="mt-5">
              <Swiper
                slidesPerView={2}
                loop={true}
                spaceBetween={6}
                breakpoints={{
                  640: {
                    slidesPerView: 3,
                    spaceBetween: 12,
                  },
                  768: {
                    slidesPerView: 4,
                    spaceBetween: 16,
                  },
                  1024: {
                    slidesPerView: 6,
                    spaceBetween: 20,
                  },
                }}
                modules={[Navigation, Autoplay]}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                className=" mt-4 space-x-2 mySwiper cursor-pointer"
              >
                {categories?.map((c) => (
                  <SwiperSlide key={c?.id}>
                    <Card sx={{ maxWidth: 1000 }} className="my-2">
                      <Link
                        to={`/products-collection/category-products/${c?.slug}`}
                      >
                        <CardActionArea>
                          <CardMedia
                            component="img"
                            className=""
                            image={c?.imageUrl}
                            alt={c?.name}
                          />
                          <CardContent>
                            <h4 className="text-lg font-semibold h-12 text-center">
                              {c?.name?.length > 20
                                ? `${c?.name?.slice(0, 20)}...`
                                : c?.name}
                            </h4>
                          </CardContent>
                        </CardActionArea>
                      </Link>
                    </Card>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default Categories;
