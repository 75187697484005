import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaRegTrashAlt } from 'react-icons/fa';
import swal from 'sweetalert';
import { Button } from '@mui/material';
import Title from '../component/Helmet/Title';
import {
  useCartDeleteMutation,
  useGetCartQuery,
} from '../redux/Features/ServicesApi';
import ViewCartSkeleton from '../component/Skeleton/ViewCartSkeleton';
import { DataContext } from '../provider/ContextProvider';

//* View cart page
const ViewCart = () => {
  const { ip } = useContext(DataContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Get the user cart all products.
  const { data: carts, isLoading } = useGetCartQuery(ip);

  // Datalayer Code...
  useEffect(() => {
    if (carts?.data?.carts && Array.isArray(carts.data.carts)) {
      const items = carts.data.carts.map((cartItem) => ({
        item_name: cartItem.product.name,
        item_id: cartItem.product.id,
        price: cartItem.product.regular_price,
        item_brand: 'Unknown',
        item_category: 'Unknown',
        item_variant: '',
        item_list_name: '',
        item_list_id: '',
        index: 0,
        quantity: cartItem.qty,
      }));

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: 'view_cart',
        ecommerce: {
          items: items,
        },
      });
    } else {
      console.error('Cart data is not an array:', carts);
    }
  }, [carts]);
  // Datalayer Code...

  // Delete cart mutation.
  const [deleteCart] = useCartDeleteMutation();

  // Delete cart's products handler.
  const deleteHandler = async (id) => {
    swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this product!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          const res = await deleteCart(id);
          swal('Good job!', res?.data?.message, 'success');
        } catch (error) {
          console.log(error);
        }
      } else {
        swal('Your product is safe!');
      }
    });
  };

  return (
    <>
      <section className="mt-12 lg:mt-44">
        {/* React helmet. */}
        <Title title={'View cart'} content={'This is view cart page'} />
        {isLoading ? (
          // View cart loader skeleton.
          <ViewCartSkeleton />
        ) : (
          <>
            <div>
              <button className="px-2 py-3 lg:px-4 lg:py-4 bg-primary-500 text-white rounded-md hover:bg-green-700">
                <Link to="/">Continue Shopping</Link>
              </button>
            </div>
            {/* <!-- Table for large device--> */}
            <div className="hidden lg:block">
              <div className="relative overflow-x-auto mt-10">
                {carts?.data?.carts?.length > 0 ? (
                  <table className="w-full text-sm text-left rtl:text-right text-base_500">
                    <thead className="text-md text-base_700 uppercase bg-base">
                      <tr>
                        <th scope="col" className="px-4 py-4">
                          Image
                        </th>
                        <th scope="col" className="py-4">
                          Product Name
                        </th>
                        <th scope="col" className="p-4">
                          Price
                        </th>
                        <th scope="col" className="px-4 py-4">
                          Quantity
                        </th>
                        <th scope="col" className="p-4">
                          Total
                        </th>
                        <th scope="col" className="p-2">
                          Remove
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {carts?.data?.carts?.map((cProduct) => (
                        <tr
                          className="text-black border-b bg-base_300"
                          key={cProduct?.id}
                        >
                          <td className="px-4 py-2">
                            <img
                              src={cProduct?.product?.imageUrl}
                              alt={cProduct?.product?.name}
                              className="w-20 h-20"
                            />
                          </td>
                          <td className="p-2 font-medium text-md">
                            <h2 className="w-44 lg:w-96">
                              {cProduct?.product?.name?.slice(0, 35)}
                            </h2>
                          </td>
                          <td className="px-4 py-2 font-medium whitespace-nowrap text-md">
                            {cProduct?.price}
                          </td>
                          <td className="font-medium whitespace-nowrap">
                            <p className="py-2 w-24 border border-primary text-center rounded-md text-md">
                              {cProduct?.qty}
                            </p>
                          </td>
                          <td className="px-4 py-2 font-medium whitespace-nowrap text-md">
                            <p className="">
                              {cProduct?.price * cProduct?.qty}
                            </p>
                          </td>
                          <td className="px-6 py-2 text-center">
                            <Button
                              variant="text"
                              color="error"
                              onClick={() => deleteHandler(cProduct?.id)}
                            >
                              <FaRegTrashAlt />
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div>
                    <p className="text-center text-xl">
                      You haven't added any product
                    </p>
                  </div>
                )}
              </div>
              {/* proceed to checkout button. */}
              <div className="text-center my-8">
                <Button variant="contained" color="success">
                  <Link to="/checkout">
                    Proceed to checkout
                    <i className="fa-solid fa-right-from-bracket"></i>
                  </Link>
                </Button>
              </div>
            </div>

            {/* cart for small device */}
            <div className="block lg:hidden mt-8">
              {carts?.data?.carts?.length > 0 ? (
                carts?.data?.carts?.map((c) => (
                  <div
                    key={c?.id}
                    className="flex items-center justify-between gap-5 border"
                  >
                    <div className="w-1/3">
                      <img src={c?.product?.imageUrl} alt={c?.product?.name} />
                    </div>
                    <div className="w-2/3">
                      <h3>{c?.product?.name?.slice(0, 35)}</h3>
                      <p>Price: {c?.price} TK.</p>
                      <p>Quantity: {c?.qty} </p>
                      <p>Total Price: {c?.qty * c?.price} TK.</p>
                    </div>
                    <div>
                      <Button
                        onClick={() => deleteHandler(c?.id)}
                        variant="contained"
                        color="error"
                      >
                        <FaRegTrashAlt />
                      </Button>
                    </div>
                  </div>
                ))
              ) : (
                <div>
                  <p>You haven't added any products</p>
                </div>
              )}
              {/* proceed to checkout button. */}
              <div className="text-center my-8">
                <Button variant="contained" color="success">
                  <Link to="/checkout">
                    Proceed to checkout
                    <i className="fa-solid fa-right-from-bracket"></i>
                  </Link>
                </Button>
              </div>
            </div>
          </>
        )}
      </section>
    </>
  );
};

export default ViewCart;
