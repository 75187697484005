import React, { useEffect } from "react";
import Banner from "../component/Home/Banner";
import Categories from "../component/Home/Categories";
import AllProducts from "../component/Home/AllProducts";
import NewArrival from "../component/Home/NewArrival";
import HotProducts from "../component/Home/HotProducts";
import DiscountProducts from "../component/Home/DiscountProducts";
import Title from "../component/Helmet/Title";
import {
  useGetCategoriesQuery,
  useGetTypeProductsQuery,
} from "../redux/Features/ServicesApi";

//* This is Home page
const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // get types of products from backend.
  const { data, isLoading } = useGetTypeProductsQuery();

  const hotProducts = data?.data?.hotProducts;
  const newProducts = data?.data?.newProducts;
  const discountProducts = data?.data?.discountProducts;
  const regularProducts = data?.data?.regularProducts;

  // get categories of products from backend.
  const { data: categories, isLoading: isCategoriesLoading } =
    useGetCategoriesQuery();

  return (
    <div>
      {/* react helmet. */}
      <Title
        title={"Home"}
        content={"This is Home page of banngomart website."}
      />
      {/* Banner component of home page. */}
      <Banner />
      {/* categories component of home page. */}
      <Categories
        isLoading={isCategoriesLoading}
        categories={categories?.data}
      />
      {/* Hot products section. */}
      <HotProducts products={hotProducts?.data} isLoading={isLoading} />
      {/* New arrival section. */}
      <NewArrival products={newProducts?.data} isLoading={isLoading} />
      {/* Discount products section. */}
      <DiscountProducts
        products={discountProducts?.data}
        isLoading={isLoading}
      />
      {/* Regular products section */}
      <AllProducts AllProducts={regularProducts?.data} isLoading={isLoading} />
    </div>
  );
};

export default Home;
