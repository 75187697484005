import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Rating,
  Typography,
} from '@mui/material';
import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAddToCartMutation } from '../../redux/Features/ServicesApi';
import swal from 'sweetalert';
import { DataContext } from '../../provider/ContextProvider';

const ProductsComponent = ({ products }) => {
  const navigate = useNavigate();
  const { ip } = useContext(DataContext);
  const [addToCart] = useAddToCartMutation();

  const quickOrderHandler = async (id) => {
    const product = products.find((p) => p?.id === id);

    const payload = {
      ip_address: ip,
      qty: 1,
      price: product?.discount_price || product?.regular_price,
      color: null,
      size: null,
    };
    try {
      const response = await addToCart({ payload, id });
      swal('Good job!', response?.data?.message, 'success');
      navigate('/checkout');
    } catch (error) {
      console.error('Error adding to cart:', error);
    }
  };

  return (
    <div>
      <div className="mt-5 grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5 2xl:grid-cols-6 items-center gap-4 mx-auto">
        {products?.length > 0 ? (
          products?.map((product) => (
            <div key={product.id} className="relative">
              <Card
                sx={{ maxWidth: 1000 }}
                className="my-2 border border-primary-500"
              >
                <Link
                  to={
                    product?.is_variable > 0
                      ? `/product-details-v/${product?.slug}`
                      : `/product-details/${product?.slug}`
                  }
                >
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      image={product?.imageUrl}
                      alt={product?.name}
                    />
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="p"
                        component="div"
                        className="font-medium text-center"
                      >
                        {product?.name.slice(0, 26)}
                      </Typography>
                      <div className="flex justify-center mb-2">
                        <Rating
                          name="read-only"
                          precision={0.5}
                          value={product?.rating}
                          readOnly
                        />
                      </div>
                      {product?.discount_price ? (
                        <div className="hidden md:block">
                          <div className="flex items-center gap-2 mx-auto justify-center">
                            <Typography
                              gutterBottom
                              variant="p"
                              component="div"
                              className="font-semibold text-primary-500 text-center"
                            >
                              {product?.discount_price}
                            </Typography>
                            <Typography
                              gutterBottom
                              variant="p"
                              component="div"
                              className="font-semibold line-through text-secondary-400 text-center"
                            >
                              {product?.regular_price}
                            </Typography>
                          </div>
                        </div>
                      ) : (
                        <Typography
                          gutterBottom
                          variant="p"
                          component="div"
                          className="font-semibold hidden md:block text-primary-500 text-center"
                        >
                          {product?.regular_price}
                        </Typography>
                      )}
                    </CardContent>
                  </CardActionArea>
                </Link>

                <CardActions>
                  <Button
                    variant="contained"
                    color="success"
                    className="w-full font-medium"
                    onClick={() => quickOrderHandler(product?.id)}
                  >
                    Quick Order
                  </Button>
                </CardActions>
              </Card>
              {product?.product_type !== 'feature' && (
                <div className="absolute top-3 right-0.5 z-20">
                  <span
                    className={`${
                      product?.product_type === 'new'
                        ? 'bg-primary-100 text-primary-500'
                        : 'bg-red-100 text-red-500'
                    }  font-medium me-2 px-2.5 py-0.5 rounded uppercase`}
                  >
                    {product?.product_type}
                  </span>
                </div>
              )}
            </div>
          ))
        ) : (
          <div>
            <p className="text-center text-xl font-medium text-red-500">
              There is no Products
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductsComponent;
