import { Card, CardActionArea, CardContent, CardMedia } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import AllCategoriesSkeleton from "./Skeleton/AllCategoriesSkeleton";

// categories component
const CategoriesComponent = ({ categories, isLoading }) => {
  return (
    <div>
      {isLoading ? (
        // This is loader skeleton of categories
        <AllCategoriesSkeleton />
      ) : (
        // dynamic categories from backend
        <div className="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 items-center justify-center gap-4 mx-auto">
          {categories.map((c, index) => (
            <div key={index}>
              <Card sx={{ maxWidth: 1000 }} className="my-2">
                <Link to={`/products-collection/category-products/${c?.slug}`}>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      className="h-32"
                      image={c?.imageUrl}
                      alt={c?.name}
                    />
                    <CardContent>
                      <h4 className="text-lg font-semibold h-12 text-center">
                        {c?.name}
                      </h4>
                    </CardContent>
                  </CardActionArea>
                </Link>
              </Card>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CategoriesComponent;
