import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import ProductsComponent from '../../component/Products/ProductsComponent';
import { Button } from '@mui/material';
import Title from '../../component/Helmet/Title';

const OfferProducts = () => {
  const { category, id } = useParams();

  // const [tab, setTab] = useState(category);
  // const [catId, setCatId] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);

    // if name not equal all then setCatId
    if (category !== 'all') {
      // setCatId(id);
    }
  }, [category, id]);

  // const tabHandler = (name, id) => {
  //   setTab(name);

  //   if (name !== 'all') {
  //     setCatId(id);
  //   }
  // };

  return (
    <div className="my-10 lg:my-44">
      <Title title={'Offer product'} content={'This is offer product page.'} />
      <div className="bg-white flex justify-between items-center border-b py-4 pe-5">
        <div>
          <Button variant="contained" color="success">
            <Link to={'/'}>Back</Link>
          </Button>
        </div>
        <div className="flex justify-between items-center gap-4">
          <div>Sort by:</div>
          <form className="max-w-sm">
            <select
              id="filter"
              className="rounded-full text-dark focus:ring-primary focus:border-primary block w-full p-2"
            >
              <option value={'Best Match'} className="border-0">
                Best Match
              </option>
              <option value="TS">Top sells</option>
              <option value="NA">Newest arrivals</option>
              <option value="LH">Price low to high</option>
              <option value="HL">Price high to low</option>
            </select>
          </form>
        </div>
      </div>
      <ProductsComponent />
    </div>
  );
};

export default OfferProducts;
