// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Define a service using a base URL and expected endpoints
export const BaseApi = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({ baseUrl: "https://cmp.banggomart.com/api" }),
  tagTypes: ["Products", "Categories", "Cart"],
  endpoints: () => ({}),
});
