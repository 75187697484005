import { Skeleton } from "@mui/material";
import React from "react";
import ProductsComponent from "../Products/ProductsComponent";
import ProductCardSkeleton from "../Skeleton/ProductCardSkeleton";

const AllProducts = ({ AllProducts, isLoading }) => {
  return (
    <div className="my-5 lg:my-10">
      {isLoading ? (
        <Skeleton width={200} />
      ) : (
        <h2 className="text-xl lg:text-3xl font-medium lg:font-bold my-5 lg:my-10 uppercase">
          Regular Products
        </h2>
      )}
      <div>
        {isLoading ? (
          <ProductCardSkeleton />
        ) : (
          <ProductsComponent products={AllProducts} />
        )}
      </div>
    </div>
  );
};

export default AllProducts;
