import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const DropLogin = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <section className="mt-10 lg:mt-44">
        <div className="bg-white flex items-center justify-between rounded-md">
          <div className="hidden md:block">
            <img
              src="https://img.freepik.com/free-vector/computer-login-concept-illustration_114360-7962.jpg?size=626&ext=jpg&ga=GA1.1.755787080.1713958594&semt=sph"
              alt=""
            />
          </div>
          <div className="relative py-6 px-4 mx-auto rounded-lg shadow-md md:w-1/2">
            <div className="z-10 relative">
              <h2 className="text-center text-2xl lg:text-4xl font-bold mb-8">
                Dropshipper Login
              </h2>
              <form className="max-w-md mx-auto">
                <div className="mb-6">
                  <label htmlFor="email" className="block mb-2 font-semibold">
                    Email Address <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="email"
                    id="email"
                    className="border border-primary rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent block w-full py-3 px-4 bg-transparent"
                    placeholder="Enter your email"
                    required
                  />
                </div>
                <div className="my-2 relative">
                  <label
                    htmlFor="password"
                    className="block mb-2 font-semibold"
                  >
                    Password <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="password"
                    id="password"
                    placeholder="Enter your password"
                    className="border border-primary rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent block w-full py-3 px-4 bg-transparent"
                    required
                  />
                  <button
                    id="togglePassword"
                    className="absolute bottom-3 right-10 focus:outline-none"
                  >
                    <i id="passwordIcon" className="fa-solid fa-eye-slash"></i>
                  </button>
                </div>
                <div>
                  <Link to="#" className="text-blue-500">
                    Forget password?
                  </Link>
                </div>
                <div className="text-center mt-6">
                  <input
                    type="submit"
                    value="Login"
                    className=" bg-primary-500 text-white cursor-pointer hover:bg-primary-700 focus:ring-4 focus:ring-primary-700 focus:outline-none font-semibold rounded-lg w-full px-8 py-3"
                  />
                </div>
                <div className="mt-4">
                  <p>
                    Don't have an account?{" "}
                    <Link
                      to="/dropshipper/registration"
                      className="text-blue-500"
                    >
                      Register
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DropLogin;
