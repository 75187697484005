import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Title from '../component/Helmet/Title';
import { useGetOrderDetailsQuery } from '../redux/Features/ServicesApi';

const SuccessOrder = () => {
  const { order } = useParams();

  // get the order details from backend.
  const { data } = useGetOrderDetailsQuery(order);

  //Dtalayer Purpose...
  const transactionDetails = {
    transactionId: 78,
    value: data?.data?.order?.price,
    shipping: data?.data?.order?.area,
    items: data?.data?.order?.order_details?.map((order) => ({
      item_name: order.product.name,
      item_id: order.product.id,
      price: order.product.discount_price
        ? order.product.discount_price
        : order.product.regular_price,
      item_brand: 'Unknown',
      item_category: 'Unknown',
      item_variant: '',
      quantity: order.qty,
    })),
  };
  //Dtalayer Purpose...

  useEffect(() => {
    window.scrollTo(0, 0);

    // Datalayer Code..
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'purchase',
      ecommerce: {
        transaction_id: transactionDetails.transactionId,
        affiliation: 'Banggomart',
        value: transactionDetails.value,
        tax: 0,
        shipping: transactionDetails.shipping,
        currency: 'BDT',
        coupon: null,
        items: transactionDetails.items,
      },
    });
    // Datalayer Code..
  }, [
    transactionDetails.items,
    transactionDetails.shipping,
    transactionDetails.transactionId,
    transactionDetails.value,
  ]);

  return (
    <section className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-b from-pink-100 to-white relative overflow-hidden">
      <Title title={order} content={`This is success-order page.`} />
      <img
        src="/images/check.png"
        alt="checkmark"
        className="w-20 lg:w-44 mx-auto"
      />
      <h2 className="text-2xl">Order Number: {order}</h2>
      <p className="text-2xl lg:px-52 mt-4 mx-auto">
        আপনার অর্ডারটি সফলভাবে সম্পন্ন হয়েছে ।আমাদের কল সেন্টার থেকে ফোন করে
        আপনার অর্ডারটি কনফার্ম করা হবে
      </p>
      <Link
        to={'/'}
        className="px-6 py-3 bg-primary-950 text-white rounded-md hover:bg-primary-900 transition duration-300"
      >
        Continue Shopping
      </Link>

      {/* Flower Animation */}
      <div className="absolute inset-0 pointer-events-none overflow-hidden">
        {[...Array(50)].map((_, i) => (
          <img
            key={i}
            src="/rose.png"
            alt="flower"
            className="absolute w-10 h-10 animate-fall"
            style={{
              animationDuration: `${Math.random() * 5 + 3}s`,
              animationDelay: `${Math.random() * 5}s`,
              left: `${Math.random() * 100}%`,
              top: `-${Math.random() * 20}vh`,
              transform: `rotate(${Math.random() * 360}deg)`,
            }}
          />
        ))}
      </div>
    </section>
  );
};

export default SuccessOrder;
