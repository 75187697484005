import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ProductsComponent from '../../component/Products/ProductsComponent';
import { Button, Pagination, Stack } from '@mui/material';
import Title from '../../component/Helmet/Title';
import {
  useGetAllProductsQuery,
  useGetCategoriesQuery,
} from '../../redux/Features/ServicesApi';
import ProductCardSkeleton from '../../component/Skeleton/ProductCardSkeleton';

//* All Products page.
const Products = () => {
  // handle pagination page.
  const [page, setPage] = useState(1);
  const handlePageChange = (event, value) => {
    setPage(value);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Get the categories of products from backend.
  const { data: categories } = useGetCategoriesQuery();

  // Get the All Products from backend.
  const { data: AllProducts, isLoading } = useGetAllProductsQuery({ page });

  return (
    <div className="my-10 lg:my-44">
      {/* React helmet. */}
      <Title title={'All Products'} content={'This is all products page.'} />
      <div className="bg-white lg:flex justify-between items-center border-b py-4 pe-5">
        {/* back button */}
        <div className="hidden lg:block">
          <Button variant="contained" color="success">
            <Link to={'/'}>Back</Link>
          </Button>
        </div>
        {/* Product sorting. */}
        <div className="flex justify-center lg:justify-between items-center gap-4 mx-auto">
          <div>Sort by:</div>
          <form className="max-w-sm">
            <select
              id="filter"
              className="rounded-full text-dark focus:ring-primary focus:border-primary block w-full p-2"
            >
              <option value={'Best Match'} className="border-0">
                Best Match
              </option>
              <option value="TS">Top sells</option>
              <option value="NA">Newest arrivals</option>
              <option value="LH">Price low to high</option>
              <option value="HL">Price high to low</option>
            </select>
          </form>
        </div>
      </div>
      <div className="mx-auto flex-wrap flex justify-center p-2 items-center py-2 gap-4 bg-white border-b text-lg">
        <div className="mx-auto flex-wrap flex justify-center p-2 items-center py-2 gap-4 bg-white border-b text-lg">
          {/* product nav link category wise. */}
          <div>
            <Link
              to={'/products-collection'}
              className={'bg-secondary-200 border rounded-md px-3 py-2'}
            >
              All
            </Link>
          </div>

          {/* categories */}
          {categories?.data?.map((c, index) => (
            <div key={c?.id}>
              <Link
                to={`/products-collection/category-products/${c?.slug}`}
                id={c}
                className={'bg-secondary-200 border rounded-md px-3 py-2'}
              >
                {c?.name}
              </Link>
            </div>
          ))}
        </div>
      </div>

      {/* All Products */}
      {isLoading ? (
        <ProductCardSkeleton />
      ) : (
        <ProductsComponent products={AllProducts?.data?.data} />
      )}

      {/* pagination */}
      <div>
        <div className="my-4 mx-auto flex justify-center items-center w-full">
          <Stack spacing={2}>
            <Pagination
              count={AllProducts?.data?.last_page}
              page={page}
              onChange={handlePageChange}
              variant="outlined"
              shape="rounded"
              sx={{
                '& .MuiPaginationItem-root': {
                  color: 'gray',
                  '&.Mui-selected': {
                    backgroundColor: 'green',
                    color: 'white',
                  },
                  '&:hover': {
                    backgroundColor: 'lightgreen',
                  },
                },
              }}
            />
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default Products;
