import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook } from 'react-icons/fa';
import { BsTwitterX } from 'react-icons/bs';
import { FaInstagramSquare } from 'react-icons/fa';
import { FaYoutube } from 'react-icons/fa';
import { useGetGeneralDataQuery } from '../../redux/Features/ServicesApi';

const Footer = () => {
  const { data } = useGetGeneralDataQuery();

  return (
    <footer className="bg-secondary-950">
      <div className="mx-auto w-full container ">
        <div className="grid grid-cols-1 gap-8 px-4 py-6 lg:py-8 md:grid-cols-4">
          <div className="">
            <Link to={'/'}>
              <img
                src={data?.generalData?.logo_url}
                alt="banggomart"
                className="w-full mx-auto"
              />
            </Link>
            <h2 className="my-4 text-sm font-semibold text-secondary-100 uppercase ">
              Follow us
            </h2>
            <div className="flex items-center justify-start gap-5">
              <a
                href={data?.generalData?.facebook}
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebook className="w-6 h-6 lg:w-8 lg:h-8 text-primary-500" />
              </a>
              <a
                href={data?.generalData?.twitter}
                target="_blank"
                rel="noreferrer"
              >
                <BsTwitterX className="w-6 h-6 lg:w-8 lg:h-8 text-primary-500" />
              </a>
              <a
                href={data?.generalData?.instagram}
                target="_blank"
                rel="noreferrer"
              >
                <FaInstagramSquare className="w-6 h-6 lg:w-8 lg:h-8 text-primary-500" />
              </a>
              <a
                href={data?.generalData?.youtube}
                target="_blank"
                rel="noreferrer"
              >
                <FaYoutube className="w-6 h-6 lg:w-8 lg:h-8 text-primary-500" />
              </a>
            </div>
          </div>
          <div>
            <h2 className="mb-6 text-sm font-semibold text-secondary-100 uppercase ">
              Company
            </h2>
            <ul className="text-secondary-400  font-medium">
              <li className="mb-4">
                <a
                  href="https://cmp.banggomart.com/about-us"
                  className=" hover:underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  About
                </a>
              </li>
              <li className="mb-4">
                <a
                  href="https://cmp.banggomart.com/career"
                  className="hover:underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  Careers
                </a>
              </li>
              <li className="mb-4">
                <a
                  href="https://cmp.banggomart.com/all/blogs"
                  className="hover:underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  Blog
                </a>
              </li>
              <li className="mb-4">
                <a
                  href="https://cmp.banggomart.com/vendor/login/form"
                  className="hover:underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  Vendor
                </a>
              </li>
              <li className="mb-4">
                <a
                  href="https://cmp.banggomart.com/dropshipper/registration-form"
                  className="hover:underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  Dropshipper
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h2 className="mb-6 text-sm font-semibold text-secondary-100 uppercase ">
              Policy
            </h2>
            <ul className="text-secondary-400  font-medium">
              <li className="mb-4">
                <a
                  href="https://cmp.banggomart.com/privacy-policy"
                  className="hover:underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </li>
              <li className="mb-4">
                <a
                  href="https://cmp.banggomart.com/term-conditions"
                  className="hover:underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms &amp; Conditions
                </a>
              </li>
              <li className="mb-4">
                <a
                  href="https://cmp.banggomart.com/refund-policy"
                  className="hover:underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  Refund Policy
                </a>
              </li>
              <li className="mb-4">
                <a
                  href="https://cmp.banggomart.com/payment-policy"
                  className="hover:underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  Payment Policy
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h2 className="mb-6 text-sm font-semibold text-secondary-100 uppercase ">
              Help center
            </h2>
            <ul className="text-secondary-400  font-medium">
              <li className="mb-4">
                <a
                  href="https://cmp.banggomart.com/contact-us"
                  className="hover:underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  Contact Us
                </a>
              </li>
              <div className="">
                <h4 className=" text-sm font-semibold text-secondary-100 ">
                  Address:
                </h4>
                <p className="my-2 text-secondary-400">
                  {data?.generalData?.address}
                </p>
                <h3 className=" text-sm font-semibold text-secondary-100 ">
                  Phone:
                </h3>
                <a
                  className="my-2 text-secondary-400"
                  href={`tel: ${data?.generalData?.phone}`}
                >
                  {data?.generalData?.phone}
                </a>
                <h3 className=" text-sm font-semibold text-secondary-100  ">
                  Email:
                </h3>
                <a
                  href="mailto: banggomartbd@gmail.com"
                  className="my-2 text-secondary-400 text-sm lg:text-base"
                >
                  {data?.generalData?.email}
                </a>
              </div>
            </ul>
          </div>
        </div>
        <div className="px-4 py-6 border-t  md:flex md:items-center md:justify-between">
          <span className="text-sm text-secondary-300  sm:text-center">
            © {new Date().getFullYear()} <Link to="/">Banggomart™</Link>. All
            Rights Reserved.
          </span>
          <br />

          <span className="text-sm text-secondary-300 sm:text-center">
            Design & Developed by{' '}
            <a
              href={'https://webcoder-it.com/'}
              target="_blank"
              rel="noreferrer"
              className="underline text-primary-500"
            >
              Webcoder-IT
            </a>
          </span>
          <div className="md:flex items-center gap-3 mb-16 md:mb-0">
            <p className="text-sm text-secondary-300 sm:text-center">
              Payment Methods
            </p>
            <div className="flex flex-wrap items-center gap-2">
              <img src="/images/nagad.png" alt="" className="w-20 bg-white" />
              <img
                src="/images/visa-card.png"
                alt=""
                className="w-20 bg-white"
              />
              <img
                src="/images/master-card.webp"
                alt=""
                className="w-16 bg-white"
              />
              <img src="/images/ssl-thumb.jpg" alt="" className="w-20" />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
