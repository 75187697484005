import { createBrowserRouter } from 'react-router-dom';
import Main from '../layout/MainLayout/Main';
import Home from '../pages/Home';
import Error from '../pages/Error';
import ProductDetails from '../pages/Products/ProductDetails';
import VariableProductDetails from '../pages/Products/VariableProductDetails';
import ViewCart from '../pages/ViewCart';
import Checkout from '../pages/Checkout';
import SuccessOrder from '../pages/success-order';
import Products from '../pages/Products/Products';
import Login from '../pages/vendor/Login';
import Registration from '../pages/vendor/Registration';
import DropLogin from '../pages/dropshipper/Login';
import DropRegistration from '../pages/dropshipper/Registration';
import OfferProducts from '../pages/Products/OfferProducts';
import Categories from '../pages/Categories';
import Playground from '../pages/Playground';
import CategoryProducts from '../pages/Products/CategoryProducts';
import SubcategoryProducts from '../pages/Products/SubcategoryProducts';
import SearchProducts from '../pages/Products/SearchProducts';

export const router = createBrowserRouter([
  {
    path: '/',
    errorElement: <Error />,
    element: <Main />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/categories',
        element: <Categories />,
      },
      {
        path: '/products-collection',
        element: <Products />,
      },
      {
        path: '/products-collection/category-products/:slug',
        element: <CategoryProducts />,
      },
      {
        path: '/products-collection/subcategory-products/:slug',
        element: <SubcategoryProducts />,
      },
      {
        path: '/offer-products',
        element: <OfferProducts />,
      },
      {
        path: '/products-collection/search-products/:name',
        element: <SearchProducts />,
      },
      {
        path: '/product-details/:slug',
        element: <ProductDetails />,
      },
      {
        path: '/product-details-v/:slug',
        element: <VariableProductDetails />,
      },
      {
        path: '/view-cart',
        element: <ViewCart />,
      },
      {
        path: '/checkout',
        element: <Checkout />,
      },
      {
        path: '/success-order/:order',
        element: <SuccessOrder />,
      },
      {
        path: '/vendor/login',
        element: <Login />,
      },
      {
        path: '/vendor/registration',
        element: <Registration />,
      },
      {
        path: '/dropshipper/login',
        element: <DropLogin />,
      },
      {
        path: '/dropshipper/registration',
        element: <DropRegistration />,
      },
      {
        path: '/playground',
        element: <Playground />,
      },
    ],
  },
]);
