import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const DropRegistration = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <section className="mt-10 lg:mt-44">
        <div className="md:flex items-center justify-between gap-4 bg-white py-4 rounded-md">
          <div className="hidden md:block">
            <img
              src="https://img.freepik.com/free-vector/computer-login-concept-illustration_114360-7962.jpg?size=626&ext=jpg&ga=GA1.1.755787080.1713958594&semt=sph"
              alt=""
            />
          </div>
          <div className="relative bg-white py-8 px-4 mx-auto rounded-lg shadow-md">
            <div className="absolute top-0 left-0 w-full h-full bg-opacity-50 bg-white rounded-lg"></div>
            <div className="z-10 relative">
              <h2 className="text-center text-2xl lg:text-4xl font-bold mb-8">
                Dropshipper Registration
              </h2>
              <form className="mx-auto">
                <div className="lg:flex items-center gap-4 justify-between">
                  <div className="mb-6 w-full">
                    <label htmlFor="fname" className="block mb-2 font-semibold">
                      First Name <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      id="fname"
                      className="border border-primary rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent block w-full py-3 px-4 bg-transparent"
                      placeholder="Enter your first name"
                      required
                    />
                  </div>
                  <div className="mb-6 w-full">
                    <label htmlFor="lname" className="block mb-2 font-semibold">
                      Last Name <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      id="lname"
                      className="border border-primary rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent block w-full py-3 px-4 bg-transparent"
                      placeholder="Enter your last name"
                      required
                    />
                  </div>
                </div>
                <div className="lg:flex items-center gap-4 justify-between">
                  <div className="mb-6 w-full">
                    <label htmlFor="email" className="block mb-2 font-semibold">
                      Email <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="email"
                      id="email"
                      className="border border-primary rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent block w-full py-3 px-4 bg-transparent"
                      placeholder="Enter your email"
                      required
                    />
                  </div>
                  <div className="mb-6 w-full">
                    <label htmlFor="phone" className="block mb-2 font-semibold">
                      Phone <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="tel"
                      id="phone"
                      className="border border-primary rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent block w-full py-3 px-4 bg-transparent"
                      placeholder="Enter your phone number"
                      required
                    />
                  </div>
                </div>
                <div className="lg:flex items-center gap-4 justify-between">
                  <div className="mb-6 w-full">
                    <label
                      htmlFor="domain"
                      className="block mb-2 font-semibold"
                    >
                      Website Domain Name
                      <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      id="domain"
                      className="border border-primary rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent block w-full py-3 px-4 bg-transparent"
                      placeholder="Enter your domain"
                      required
                    />
                  </div>
                  <div className="mb-6 w-full">
                    <label
                      htmlFor="address"
                      className="block mb-2 font-semibold"
                    >
                      Address <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      id="address"
                      className="border border-primary rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent block w-full py-3 px-4 bg-transparent"
                      placeholder="Enter your address"
                      required
                    />
                  </div>
                </div>
                <div className="lg:flex items-center gap-4 justify-between">
                  <div className="my-2 relative w-full">
                    <label
                      htmlFor="password"
                      className="block mb-2 font-semibold"
                    >
                      Password <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="password"
                      id="password"
                      placeholder="Enter your password"
                      className="border border-primary rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent block w-full py-3 px-4 bg-transparent"
                      required
                    />
                    <button
                      id="togglePassword"
                      className="absolute bottom-3 right-10 focus:outline-none"
                    >
                      <i
                        id="passwordIcon"
                        className="fa-solid fa-eye-slash"
                      ></i>
                    </button>
                  </div>
                  <div className="my-2 relative w-full">
                    <label
                      htmlFor="con_password"
                      className="block mb-2 font-semibold"
                    >
                      Confirm Password <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="password"
                      id="con_password"
                      placeholder="Enter your confirm password"
                      className="border border-primary rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent block w-full py-3 px-4 bg-transparent"
                      required
                    />
                    <button
                      id="toggleCon_Password"
                      className="absolute bottom-3 right-10 focus:outline-none"
                    >
                      <i
                        id="con_PasswordIcon"
                        className="fa-solid fa-eye-slash"
                      ></i>
                    </button>
                  </div>
                </div>
                <div className="my-2">
                  <label
                    htmlFor="logo"
                    className="block mb-2 font-medium text-gray-900"
                  >
                    Logo <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="file"
                    id="logo"
                    placeholder="Select your logo"
                    className="block w-full text-gray-900 border border-green-600 rounded-lg cursor-pointer"
                    required
                  />
                  <p
                    className="mt-1 text-sm text-gray-700"
                    id="file_input_help"
                  >
                    SVG, PNG, JPG or GIF (MAX. 800x400px).
                  </p>
                </div>
                <div className="my-3">
                  <p>
                    Already have an account?{" "}
                    <Link to="/dropshipper/login" className="text-blue-500">
                      Login
                    </Link>
                  </p>
                </div>
                <div className="text-center mt-6">
                  <input
                    type="submit"
                    value="Register"
                    className=" bg-primary-500 text-white cursor-pointer hover:bg-primary-700 focus:ring-4 focus:ring-primary-700 focus:outline-none font-semibold rounded-lg w-full px-8 py-3"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DropRegistration;
