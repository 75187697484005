import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FaRegTrashAlt } from 'react-icons/fa';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import Title from '../component/Helmet/Title';
import {
  useCartDeleteMutation,
  useGetCartQuery,
  usePlaceOrderMutation,
} from '../redux/Features/ServicesApi';
import CheckoutSkeleton from '../component/Skeleton/CheckoutSkeleton';
import { DataContext } from '../provider/ContextProvider';

const Checkout = () => {
  const { ip } = useContext(DataContext);
  const navigate = useNavigate();

  const { data: carts, isLoading } = useGetCartQuery(ip);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //Datalayer Code...
  useEffect(() => {
    if (carts?.data?.carts && Array.isArray(carts.data.carts)) {
      const items = carts.data.carts.map((cartItem) => ({
        item_name: cartItem.product.name,
        item_id: cartItem.product.id,
        price: cartItem.product.regular_price,
        item_brand: 'Unknown',
        item_category: 'Unknown',
        item_variant: '',
        item_list_name: '',
        item_list_id: '',
        index: 0,
        quantity: cartItem.qty,
      }));

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ ecommerce: null }); // Clear previous ecommerce object to avoid data leakage
      window.dataLayer.push({
        event: 'begin_checkout',
        ecommerce: {
          items: items,
        },
      });
    } else {
      console.error('Cart data is not an array:', carts);
    }
  }, [carts]);
  //Datalayer Code...

  // react hook form
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      delivery: '60',
    },
  });

  const deliveryValue = watch('delivery'); // watch the delivery field

  const handleDeliveryChange = (event) => {
    setValue('delivery', event.target.value); // set the value of the delivery field
  };

  // place order mutation
  const [confirmOrder] = usePlaceOrderMutation();

  // products details
  const productsDetails = carts?.data?.carts
    ? carts?.data?.carts?.map((c) => ({
        id: c?.product?.id,
        color: c?.color ? c?.color : null,
        price: c?.price,
        qty: c?.qty,
        size: c?.size ? c?.size : null,
      }))
    : [];

  // Total cards quantity
  const TotalQuantity = carts?.data?.carts
    ? carts?.data?.carts
        ?.reduce((total, currentProduct) => total + currentProduct.qty, 0)
        .toString()
    : 0;

  // submit handler or place order handler
  const onSubmit = async (data) => {
    // subtotal
    const subTotal = (carts?.data?.subTotal + Number(deliveryValue)).toString();

    // order details
    const order = {
      ip_address: ip,
      customer_name: data.name,
      customer_phone: data.phone,
      delivery_area: data.delivery,
      customer_address: data.address,
      price: subTotal,
      product_quantity: Number(TotalQuantity),
      payment_type: 'cod',
      order_type: 'Website',
      products: productsDetails,
    };

    // if the products details exist or length grater than ZERO order will be placed.
    if (productsDetails.length > 0) {
      try {
        const res = await confirmOrder(order);
        // if successfully placed order than navigate user to success-order page
        if (res?.data?.success === true) {
          const orderId = res?.data?.data?.orderId;
          navigate(`/success-order/${orderId}`);
        }
      } catch (error) {
        // catch error
        // console.log(error);
      }
    } else {
      // If product details not exist or length not grater than ZERO than show a modal which indicate you to add product to cart.
      swal({
        title: 'Please add to cart products.',
        icon: 'error',
      });
    }
    // reset the order details.
    reset();
  };

  // delete api from RTK query
  const [deleteCart] = useCartDeleteMutation();

  // carts delete handler
  const deleteHandler = async (id) => {
    swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this product!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          const res = await deleteCart(id);
          swal('Good job!', res?.data?.message, 'success');
        } catch (error) {
          console.log(error);
        }
      } else {
        swal('Your product is safe!');
      }
    });
  };

  return (
    // <!-- Checkout page -->
    <section className="mt-10 lg:mt-44">
      {/* react helmet */}
      <Title title={'Checkout'} content={'This is checkout page'} />
      {/* if carts loading then show loader skeleton */}
      {isLoading ? (
        <CheckoutSkeleton />
      ) : (
        // order details form.
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="lg:flex justify-between items-start gap-5 mx-auto min-h-full"
          id="checkout_form"
        >
          <div className="w-full xl:w-2/3 rounded-md bg-white shadow-xl py-2 lg:py-10 px-2 lg:px-6 my-6 lg:my-0">
            <h3 className="text-xl lg:text-2xl font-semibold py-2">
              Billing / Shipping Details
            </h3>
            <div className="lg:flex justify-between items-center gap-4 my-4">
              <div className="w-full">
                <input
                  type="text"
                  name="name"
                  placeholder="Enter full Name*"
                  className="bg-base lg:py-4 w-full border border-base_300 text-dark rounded-lg focus:ring-primary focus:border-primary block p-2.5"
                  {...register('name', { required: true })}
                  aria-invalid={errors.name ? 'true' : 'false'}
                />
                <p className="py-1">
                  {errors.name?.type === 'required' && (
                    <small role="alert" className="text-danger">
                      name is required
                    </small>
                  )}
                </p>
              </div>
              <div className="w-full">
                <input
                  type="tel"
                  name="phone"
                  placeholder="Phone*"
                  className="bg-base w-full lg:py-4 border border-base_300 text-dark rounded-lg focus:ring-primary focus:border-primary block p-2.5"
                  {...register('phone', { required: true })}
                />
                <p className="py-1">
                  {errors.phone?.type === 'required' && (
                    <small role="alert" className="text-danger">
                      Phone is required
                    </small>
                  )}
                </p>
              </div>
            </div>
            <div className="my-4">
              <textarea
                name="address"
                cols="30"
                rows="5"
                placeholder="Enter your full Address*"
                className="bg-base w-full lg:py-4 border border-base_300 text-dark rounded-lg focus:ring-primary focus:border-primary block p-2.5"
                {...register('address', { required: true })}
                aria-invalid={errors.address ? 'true' : 'false'}
              ></textarea>
              <p className="py-1">
                {errors.address?.type === 'required' && (
                  <small role="alert" className="text-danger">
                    Address is required
                  </small>
                )}
              </p>
            </div>
            <div className="my-4">
              <div className="mb-2">
                <input
                  type="radio"
                  value="60"
                  name="delivery"
                  id="inside"
                  className="defaultChecked"
                  {...register('delivery', { required: true })}
                  defaultChecked
                  onChange={handleDeliveryChange}
                />
                <label htmlFor="inside">Inside Dhaka(60 TK.)</label>
              </div>
              <div>
                <input
                  type="radio"
                  name="delivery"
                  value="120"
                  id="outside"
                  {...register('delivery', { required: true })}
                  onChange={handleDeliveryChange}
                />
                <label htmlFor="outside">Outside Dhaka(120 TK.)</label>
              </div>
            </div>
          </div>
          <div className="w-full xl:w-1/3 bg-white rounded-md shadow-xl p-2 lg:p-6 h-fit">
            <div className="overflow-y-scroll h-[200px]">
              {/* user product of cart. */}
              {carts?.data?.carts?.length > 0 ? (
                <>
                  {carts?.data?.carts?.map((cProducts) => (
                    <div
                      className="flex justify-between border items-center mx-auto gap-4 my-4"
                      key={cProducts?.id}
                    >
                      <div>
                        <img
                          src={cProducts?.product?.imageUrl}
                          alt={cProducts?.product?.name}
                          className="w-20"
                        />
                      </div>
                      <div className="w-72">
                        <h4 className="block lg:hidden">
                          {cProducts?.product?.name.slice(0, 35)}...
                        </h4>
                        <h4 className="hidden lg:block">
                          {cProducts?.product?.name}
                        </h4>
                        <div>
                          <p className="text-primary font-medium">
                            {cProducts?.price * cProducts?.qty} TK.
                          </p>
                          <p>{cProducts?.qty} Item</p>
                          <div className="flex items-center gap-2 mx-auto">
                            <p>
                              Size:{' '}
                              {cProducts?.size ? cProducts?.size : 'No size'}
                            </p>
                            <p>
                              Color:
                              {cProducts?.color ? cProducts?.color : 'No color'}
                            </p>
                          </div>
                        </div>
                      </div>
                      <Button
                        variant="text"
                        color="error"
                        onClick={() => deleteHandler(cProducts?.id)}
                      >
                        <FaRegTrashAlt />
                      </Button>
                    </div>
                  ))}
                </>
              ) : (
                "You haven't added any product!"
              )}
            </div>

            <hr className="border my-2" />
            <div>
              <div className="flex justify-between my-1 items-center">
                <p className="font-semibold">Sub Total</p>
                <p>{carts?.data?.subTotal} Tk.</p>
              </div>
              <div className="flex justify-between my-1 items-center">
                <p className="font-semibold">Delivery Charge</p>
                <p>{deliveryValue} Tk.</p>
              </div>
              <hr className="border my-2" />
              <div className="flex justify-between items-center">
                <p className="font-semibold">Grand Total</p>
                <p>{carts?.data?.subTotal + Number(deliveryValue)} TK.</p>
              </div>
              <div className="mt-4">
                <div>
                  <p className="my-1 font-semibold">Select Payment Method</p>

                  <input
                    type="radio"
                    name="paymentMethod"
                    id=""
                    value="Cash on Delivery"
                    defaultChecked
                  />
                  <label htmlFor="paymentMethod">Cash on delivery</label>
                </div>
                <div className="mt-2">
                  <button className="w-full text-secondary-50 bg-primary-500 py-3 rounded-lg">
                    Place an Order
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </section>
  );
};

export default Checkout;
