import React from "react";

const Reviews = ({ reviews }) => {
  return (
    <div className=" mx-auto px-4">
      <h2 className="text-2xl font-bold mb-4">Customer Reviews</h2>
      {reviews?.length > 0 ? (
        reviews?.map((review, index) => (
          <div
            key={index}
            className="mb-4 p-4 border rounded-lg shadow-md bg-white"
          >
            <div className="flex items-center justify-between">
              <h3 className="text-lg font-semibold">{review?.name}</h3>
              <div className="flex items-center">
                <span className="text-yellow-500 font-semibold">
                  {review?.rating}
                </span>
                <svg
                  className="w-5 h-5 text-yellow-500 ml-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M9.049.566c.3-.921 1.603-.921 1.902 0l2.065 6.35a1 1 0 00.95.69h6.788c.969 0 1.371 1.24.588 1.81l-5.488 4.018a1 1 0 00-.363 1.118l2.065 6.35c.3.921-.755 1.688-1.538 1.118L10 15.431l-5.488 4.018c-.783.57-1.837-.197-1.538-1.118l2.065-6.35a1 1 0 00-.363-1.118L.186 9.416C-.597 8.846-.194 7.606.775 7.606h6.788a1 1 0 00.95-.69L9.049.566z" />
                </svg>
              </div>
            </div>
            <p className="mt-2 text-gray-700">{review?.message}</p>
          </div>
        ))
      ) : (
        <div>
          <p>There is no reviews</p>
        </div>
      )}
    </div>
  );
};

export default Reviews;
