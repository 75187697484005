import { Skeleton } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const CategoriesDropdown = () => {
  return (
    <div>
      <div className="overflow-y-auto h-96">
        <ul className="border">
          <li>
            <Link
              to={"/products-collection/men"}
              className=" font-medium rounded-lg px-5 py-2.5 flex text-center items-center justify-between"
              type="button"
            >
              <Skeleton width={350} />
            </Link>
          </li>
          <li>
            <Link
              to={"/products-collection/men"}
              className=" font-medium rounded-lg px-5 py-2.5 flex text-center items-center justify-between"
              type="button"
            >
              <Skeleton width={350} />
            </Link>
          </li>
          <li>
            <Link
              to={"/products-collection/men"}
              className=" font-medium rounded-lg px-5 py-2.5 flex text-center items-center justify-between"
              type="button"
            >
              <Skeleton width={350} />
            </Link>
          </li>
          <li>
            <Link
              to={"/products-collection/men"}
              className=" font-medium rounded-lg px-5 py-2.5 flex text-center items-center justify-between"
              type="button"
            >
              <Skeleton width={350} />
            </Link>
          </li>
          <li>
            <Link
              to={"/products-collection/men"}
              className=" font-medium rounded-lg px-5 py-2.5 flex text-center items-center justify-between"
              type="button"
            >
              <Skeleton width={350} />
            </Link>
          </li>
          <li>
            <Link
              to={"/products-collection/men"}
              className=" font-medium rounded-lg px-5 py-2.5 flex text-center items-center justify-between"
              type="button"
            >
              <Skeleton width={350} />
            </Link>
          </li>
          <li>
            <Link
              to={"/products-collection/men"}
              className=" font-medium rounded-lg px-5 py-2.5 flex text-center items-center justify-between"
              type="button"
            >
              <Skeleton width={350} />
            </Link>
          </li>
          <li>
            <Link
              to={"/products-collection/men"}
              className=" font-medium rounded-lg px-5 py-2.5 flex text-center items-center justify-between"
              type="button"
            >
              <Skeleton width={350} />
            </Link>
          </li>
          <li>
            <Link
              to={"/products-collection/men"}
              className=" font-medium rounded-lg px-5 py-2.5 flex text-center items-center justify-between"
              type="button"
            >
              <Skeleton width={350} />
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CategoriesDropdown;
