import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Autoplay, Pagination } from "swiper/modules";
import CategoriesDropdown from "./CategoriesDropdown";

const BannerSkeleton = () => {
  return (
    <section className="lg:flex items-center justify-start gap-2 container mx-auto">
      <div className="hidden lg:block lg:w-1/6 mx-auto">
        <CategoriesDropdown />
      </div>
      <div className="w-full lg:w-4/5 mx-auto">
        {/* Swiper carousel */}
        <Swiper
          slidesPerView={1}
          loop={true}
          fadeEffect={true}
          modules={[Navigation, Autoplay, Pagination]}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{ clickable: true }}
          navigation={true}
          className="mySwiper animate-pulse"
        >
          {[1, 2, 3, 4, 5, 6].map((i) => (
            <SwiperSlide key={i}>
              <div className="h-96 bg-secondary-200 animate-pulse"></div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default BannerSkeleton;
