import React from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  Typography,
  CardActions,
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

const ProductCardSkeleton = () => {
  return (
    <div className=" mt-5 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 items-center gap-4 mx-auto">
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]?.map((i) => (
        <div key={i} className="relative">
          <Card
            sx={{ maxWidth: 1000 }}
            className="my-2 border border-primary-500"
          >
            <CardActionArea>
              <Skeleton variant="rectangular" height={250} />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="p"
                  component="div"
                  className="font-medium text-center h-12"
                >
                  <Skeleton variant="text" width="80%" />
                </Typography>
                <div className="hidden md:block">
                  <div className="flex items-center gap-2 mx-auto justify-center">
                    <Typography
                      gutterBottom
                      variant="p"
                      component="div"
                      className="font-semibold text-primary-500 text-center"
                    >
                      <Skeleton
                        variant="text"
                        width="40%"
                        className="text-center"
                      />
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="p"
                      component="div"
                      className="font-semibold line-through text-secondary-400 text-center"
                    >
                      <Skeleton variant="text" width="40%" />
                    </Typography>
                  </div>
                </div>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Skeleton variant="rectangular" height={36} width="100%" />
            </CardActions>
          </Card>
          <div className="absolute top-2 left-1 right-0 z-20">
            <Skeleton variant="text" width={80} height={24} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProductCardSkeleton;
