import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";

const BreadCums = ({ category, slug, name }) => {
  const breadcrumbs = [
    <Link underline="hover" key="1" style={{ color: "green" }} to={`/`}>
      Home
    </Link>,
    <Link
      underline="hover"
      key="2"
      style={{ color: "green" }}
      to={`/products-collection/category-products/${slug}`}
    >
      {category}
    </Link>,
    <Typography key="3" color="text.primary">
      {name}
    </Typography>,
  ];
  return (
    <Stack spacing={2}>
      <Breadcrumbs separator={<NavigateNextIcon />} aria-label="breadcrumb">
        {breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
};

export default BreadCums;
