import React, { useContext, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  useCartDeleteMutation,
  useGetCartQuery,
} from '../../redux/Features/ServicesApi';
import swal from 'sweetalert';
import CartSidebarSkeleton from '../Skeleton/CartSidebarSkeleton';
import ClearIcon from '@mui/icons-material/Clear';
import { DataContext } from '../../provider/ContextProvider';

//* Menu side bar component use for small device users
const CartSideBar = ({ isOpen, setIsOpen }) => {
  const { ip } = useContext(DataContext);
  const sidebarRef = useRef();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, handleClickOutside]);

  // framer motion animation
  const container = {
    open: {
      x: 0,
      transition: { type: 'Tween', staggerChildren: 0.3, stiffness: 100 },
    },
    closed: {
      x: 350,
      transition: {
        type: 'Tween',
        stiffness: 100,
        staggerChildren: 0.1,
        staggerDirection: -1,
      },
    },
  };

  const { data: cart, isLoading } = useGetCartQuery(ip);
  const [deleteCart] = useCartDeleteMutation();

  const deleteHandler = async (id) => {
    swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover this product!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          const res = await deleteCart(id);
          swal('Good job!', res?.data?.message, 'success');
        } catch (error) {
          console.log(error);
        }
      } else {
        swal('Your product is safe!');
      }
    });
  };
  return (
    <div>
      {/* Menu sidebar component contents */}
      <motion.div
        initial="closed"
        animate={isOpen ? 'open' : 'closed'}
        variants={container}
        className={` fixed top-0 right-0 w-64 lg:w-80 h-screen z-50 `}
        ref={sidebarRef}
      >
        {isLoading ? (
          <CartSidebarSkeleton />
        ) : (
          <div className="h-full px-3 py-4 overflow-y-auto bg-secondary-50 shadow-2xl">
            <div className="flex items-center justify-between ">
              <h3 className="text-xl font-medium my-4">Cart</h3>
              <div onClick={() => setIsOpen(false)}>
                <ClearIcon />
              </div>
            </div>

            {cart?.data?.carts?.length > 0 ? (
              <motion.div className="my-5 overflow-y-scroll h-4/5">
                {cart?.data?.carts?.map((c, index) => (
                  <div
                    className="flex items-center gap-2 border shadow-md p-2"
                    key={index}
                  >
                    <div className="w-2/4">
                      <img
                        src={c?.product?.imageUrl}
                        alt={c?.product?.name}
                        className="w-full"
                      />
                    </div>
                    <div className="w-2/4">
                      <p>{c?.product?.name?.slice(0, 35)}</p>
                      <p className="mt-2">{c?.price} TK.</p>
                    </div>
                    <div className="w-1/4">
                      <Button
                        variant="text"
                        color="error"
                        onClick={() => deleteHandler(c?.id)}
                      >
                        <DeleteIcon />
                      </Button>
                    </div>
                  </div>
                ))}
              </motion.div>
            ) : (
              <div>
                <p>You haven't added product</p>
              </div>
            )}
            <div className="flex fixed bottom-0.5 z-30 items-center justify-between gap-2 w-64 lg:w-80">
              <Button variant="contained" color="success" className="w-full">
                <Link to={'/view-cart'} className="text-sm lg:text-base">
                  View Cart
                </Link>
              </Button>
              <Button variant="contained" color="success" className="w-full">
                <Link to={'/checkout'}>Checkout</Link>
              </Button>
            </div>
          </div>
        )}
      </motion.div>
    </div>
  );
};

export default CartSideBar;
