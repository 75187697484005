import { BaseApi } from "./Api";

export const ServicesApi = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getBanners: builder.query({
      query: () => "/home-sliders",
    }),
    getGeneralData: builder.query({
      query: () => "/general-data",
    }),
    getCategories: builder.query({
      query: () => "/categories",
    }),
    getTypeProducts: builder.query({
      query: () => "/type-products",
      providesTags: ["Products"],
    }),
    getCategoryProducts: builder.query({
      query: (slug) => `/filter-category-products/${slug}`,
    }),
    getSubcategoryProducts: builder.query({
      query: (slug) => `/filter-subcategory-products/${slug}`,
    }),
    getSearchProduct: builder.query({
      query: (slug) => `/product-search/${slug}`,
    }),
    getAllProducts: builder.query({
      query: ({ page }) => `/all-products?page=${page}`,
    }),
    getSingleProduct: builder.query({
      query: (name) => `/product/details/${name}`,
    }),
    getCart: builder.query({
      query: (ip) => `/get-cart-products/${ip}`,
      providesTags: ["Cart"],
    }),
    getCountCart: builder.query({
      query: (ip) => `/cart-products-count/${ip}`,
      providesTags: ["Cart"],
    }),
    getOrderDetails: builder.query({
      query: (id) => `/order-details/${id}`,
    }),

    // Mutation
    addToCart: builder.mutation({
      query: ({ payload, id }) => ({
        url: `/product/add-to-cart/${id}`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Cart"],
    }),
    cartDelete: builder.mutation({
      query: (id) => ({
        url: `/delete-cart/${id}`,
        method: "GET",
      }),
      invalidatesTags: ["Cart"],
    }),
    placeOrder: builder.mutation({
      query: (order) => ({
        url: "/confirm-order",
        method: "POST",
        body: order,
      }),
      invalidatesTags: ["Cart"],
    }),
  }),
});

export const {
  useGetBannersQuery,
  useGetAllProductsQuery,
  useGetGeneralDataQuery,
  useGetCategoriesQuery,
  useGetTypeProductsQuery,
  useGetCategoryProductsQuery,
  useGetSubcategoryProductsQuery,
  useGetSearchProductQuery,
  useGetSingleProductQuery,
  useGetCartQuery,
  useGetCountCartQuery,
  useGetOrderDetailsQuery,
  useAddToCartMutation,
  useCartDeleteMutation,
  usePlaceOrderMutation,
} = ServicesApi;
