import React, { useEffect } from "react";
import CategoriesComponent from "../component/CategoriesComponent";
import Title from "../component/Helmet/Title";
import { useGetCategoriesQuery } from "../redux/Features/ServicesApi";

//* This is categories page of small devices.
const Categories = () => {
  // get categories from backend using RTK query
  const { data: categories, isLoading: isCategoriesLoading } =
    useGetCategoriesQuery();

  // always open page from top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {/* React helmet */}
      <Title title={"Categories"} content={"This is categories page"} />
      {/* Categories component */}
      <CategoriesComponent
        isLoading={isCategoriesLoading}
        categories={categories?.data}
      />
    </div>
  );
};

export default Categories;
