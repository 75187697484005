import { Skeleton } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const HeaderSkeleton = () => {
  return (
    <section className="bg-white ">
      {/* Navbar or Header for large device */}
      <div className="hidden md:block ">
        <div className="fixed top-0 right-0 left-0 z-30">
          <div className="container mx-auto bg-white ">
            <div className="flex items-center justify-between p-4 animate-pulse">
              <div className="w-32 h-10 bg-secondary-200 rounded-lg"></div>
              <div>
                <form className="max-w-full mx-auto">
                  <div className="relative">
                    <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none"></div>
                    <Skeleton width={600} height={60} className="mx-auto" />
                  </div>
                </form>
              </div>
              <div className="cursor-pointer">
                <Skeleton width={40} height={40} />
              </div>
            </div>
          </div>
          <div className="bg-primary-500">
            <div className="flex items-center justify-start mx-auto gap-5 container ">
              <div className="relative w-1/5">
                <button
                  className="text-white w-full flex bg-primary-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg px-5 py-2.5 text-center items-center justify-between"
                  type="button"
                >
                  <Skeleton width={250} height={30} />
                </button>

                {/* <div className="absolute w-full bg-white">
                  {/* <!-- Dropdown menu -->
                </div> */}
              </div>
              {/* Navbar */}
              <div className="w-4/5">
                <ul className="py-2 text-white flex gap-4 items-center">
                  <li>
                    <Skeleton width={60} />
                  </li>
                  <li>
                    <Skeleton width={60} />
                  </li>
                  <li>
                    <Skeleton width={60} />
                  </li>
                  <li>
                    <Skeleton width={60} />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Navbar or Header for small device */}
      <div className="md:hidden block">
        <div className="container mx-auto p-4 flex bg-white items-center justify-between fixed top-0 right-0 left-0 z-30">
          <div className="w-32 h-10 bg-secondary-200 rounded-lg"></div>
          <div className="space-x-2 flex items-center gap-4">
            <div>
              <Skeleton width={30} height={30} />
            </div>
            <div className="cursor-pointer">
              <Skeleton width={30} height={30} />
            </div>
            <Skeleton width={30} height={30} />
          </div>
        </div>
        {/* fixed bottom navbar */}
        <div className="fixed bottom-0 left-0 right-0 mx-auto bg-secondary-50 z-20 p-2">
          <div className="flex items-center justify-between">
            <Link to={"/"} className="mx-auto text-center">
              <Skeleton width={30} height={60} />
            </Link>
            <Link to={"/categories"} className="mx-auto text-center">
              <Skeleton width={30} height={60} />
            </Link>
            <Link
              className="mx-auto text-center"
              to={"/products-collection/all"}
            >
              <Skeleton width={30} height={60} />
            </Link>
            <Link
              className="mx-auto text-center"
              to={"/view-cart"}
              title="Cart"
            >
              <Skeleton width={30} height={60} />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeaderSkeleton;
