import React from 'react';
import ProductsComponent from '../Products/ProductsComponent';
import ProductCardSkeleton from '../Skeleton/ProductCardSkeleton';
import { Skeleton } from '@mui/material';

const NewArrival = ({ products, isLoading }) => {
  return (
    <div className="my-5 lg:my-10">
      {isLoading ? (
        <Skeleton width={200} />
      ) : (
        products?.length > 0 && (
          <h2 className="text-xl lg:text-3xl font-medium lg:font-bold my-5 lg:my-10 uppercase">
            New Arrival Products
          </h2>
        )
      )}
      <div>
        {isLoading ? (
          <ProductCardSkeleton />
        ) : (
          products?.length > 0 && <ProductsComponent products={products} />
        )}
      </div>
    </div>
  );
};

export default NewArrival;
