import React, { useContext, useEffect } from 'react';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';
import { useAddToCartMutation } from '../redux/Features/ServicesApi';
import { DataContext } from '../provider/ContextProvider';

const QuickOrder = ({
  data,
  id,
  productPrice,
  discount_price,
  regular_price,
}) => {
  const navigate = useNavigate();
  const { ip } = useContext(DataContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  // Add to cart mutation.
  const [addToCart] = useAddToCartMutation();

  // quick order handle.
  const handleQuickOrder = async (path) => {
    const price = productPrice
      ? productPrice
      : discount_price
      ? discount_price
      : regular_price;
    const qty = data.qty || 1;
    const size = data?.size;
    const color = data?.color;

    const ip_address = ip;
    const payload = {
      ip_address,
      qty,
      price,
      color,
      size,
    };

    try {
      const response = await addToCart({ id, payload }).unwrap();
      swal('Good job!', response.message, 'success');
      navigate(path);
    } catch (error) {
      console.error('Error adding to cart:', error);
      swal('Error', 'Failed to add to cart', 'error');
    }
  };

  return (
    <div>
      <div type="button">
        <button
          onClick={() => handleQuickOrder('/checkout')}
          className="bg-primary-500 w-full lg:px-4 py-2 rounded-md text-white"
        >
          Order Now
        </button>
      </div>
    </div>
  );
};

export default QuickOrder;
